import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeCarousel from '../../helpers/carousel';
import ThemeCard from '../../helpers/card';
import { Button, ProgressBar, Image } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

// IMAGES
import yearsExperience from '../../assets/images/home_slider/blessingministryhomeboyd-1.png';
import slider1 from '../../assets/images/home_slider/slider-1.jpg';
// import slider2 from '../../assets/images/home_slider/slider-2.jpg';
import slider3 from '../../assets/images/home_slider/slider-3.jpg';
import slider4 from '../../assets/images/home_slider/slider-4.jpg';
// import school from '../../assets/images/home/school-600X400.jpg';
import schoolGirlPic from '../../assets/images/home/girl-386X294.jpg';
import schoolBoyPic from '../../assets/images/home/boy-386X294.jpg';
import communityPic from '../../assets/images/home/community-386X294.jpg';
import churchConstructionPic from '../../assets/images/home/churchConstruction-700X500.jpg';
import churchEeucaPic from '../../assets/images/home/churchEeuca-700X500.jpg';
import schoolEeucaPic from '../../assets/images/home/schoolEeuca-700X500.jpg';
import schoolGroupEeucaPic from '../../assets/images/home/schoolGroupEeuca-700X500.jpg';
// END IMAGES
import { withStore } from '../../store';

interface IHome extends WithTranslation {
  store: any
}

class Home extends React.Component<IHome> {

  componentDidMount() {
    let language = this.props.store.set('language', 'en');
    this.setState({
      language
    })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    console.log('DIDMOUNT HERE')
    if (prevState && prevState.language !== prevProps.store.get('language')) {
      console.log('Updating language')
      let language = this.props.store.get('language');
      this.setState({
        language
      })
    }
  }

  render() {
    let sliders = [
      {
        captionHeader: this.props.t('home_page.slider1.caption_header'),
        captionBody: this.props.t('home_page.slider1.caption_body'),
        img: slider4,
        alt: this.props.t('home_page.slider1.alt'),
      },
      {
        captionHeader: this.props.t('home_page.slider2.caption_header'),
        captionBody: this.props.t('home_page.slider2.caption_body'),
        img: slider1,
        alt: this.props.t('home_page.slider2.alt'),
      },
      {
        captionHeader: this.props.t('home_page.slider3.caption_header'),
        captionBody: this.props.t('home_page.slider3.caption_body'),
        img: slider3,
        alt: this.props.t('home_page.slider3.alt'),
      }
    ]
    const { t } = this.props;

    return (
      <div>
        <HomeCarousel key="CarousleHomepage" sliders={sliders} />
        {/* {this.renderSilder()} */}
        <div>
          <div className="container theme-container">
            <div className="row">
              <div className="col-sm">
                <img className="img-fluid" src={yearsExperience} alt="Yeasr of Experience" />
              </div>
              <div className="col-sm text-start">
                <h4><strong>{t('home_page.about_us.about_us')}</strong></h4>
                <h1 className="pt-3"><strong>{t('home_page.about_us.our_mision')}</strong></h1>
                <p className="text-start pt-3">{t('home_page.about_us.our_mission_description')}</p>
                <div className="mt-5">
                  <div className="row">
                    <div className="col-sm">
                      <p>{t('home_page.about_us.satisfaction')}</p>
                    </div>
                    <div className="col-sm">
                    {t('home_page.about_us.percentage')}%
                    </div>
                  </div>
                  <ProgressBar variant="success" now={99} />
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <Button type="button" className="btn btn-success"><strong>{t("home_page.about_us.button")}</strong></Button>
                </div>
              </div>
            </div>
          </div>
          <div className="theme-custom-container">
            <div className="container theme-container">
              <h4>{t("home_page.causes.title")}</h4>
              <h1 className="pb-5"><strong>{t('home_page.causes.description')}</strong></h1>
              <div className="row">
                <div className="col-sm">
                  <ThemeCard
                    title={t("home_page.causes.cards.one.title")}
                    cardImage={schoolGirlPic}
                    cardBody={t("home_page.causes.cards.one.body")}
                    buttonMessage={t("home_page.causes.cards.one.button")}
                  />
                </div>
                <div className="col-sm">
                  <ThemeCard
                    title={t("home_page.causes.cards.two.title")}
                    cardImage={schoolBoyPic}
                    cardBody={t("home_page.causes.cards.two.body")}
                    buttonMessage={t("home_page.causes.cards.two.button")}
                  />
                </div>
                <div className="col-sm">
                  <ThemeCard
                    title={t("home_page.causes.cards.three.title")}
                    cardImage={communityPic}
                    cardBody={t("home_page.causes.cards.three.body")}
                    buttonMessage={t("home_page.causes.cards.three.button")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="theme-container container-difference">
            <div className="container">
              <h1><strong>{t("home_page.difference.first_section.title")}</strong></h1>
              <div className="row mt-5">
                <div className="col-6">
                  <Image src={churchConstructionPic} alt="" thumbnail></Image>
                </div>
                <div className="col-6">
                  <Image src={schoolEeucaPic} alt="" thumbnail></Image>
                </div>
                <div className="col-6 pt-4">
                  <Image src={schoolGroupEeucaPic} alt="" thumbnail></Image>
                </div>
                <div className="col-6 pt-4">
                  <Image src={churchEeucaPic} alt="" thumbnail></Image>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="container theme-container">
              <h1><strong>{t("home_page.difference.second_section.title")}</strong></h1>
            </div>
            <div>
              <h5>{t("home_page.difference.second_section.description")}</h5>
            </div>
            <div className="mt-5 mb-5">
              <Button type="button" className="btn btn-success"><strong>{t("home_page.difference.second_section.button")}</strong></Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { Home };

export default withTranslation()(withStore(Home));