import React from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class Footer extends React.Component {
  render(){
    return (
      <div className="themeFooter">
        <div className="themeFooterTop pt-4">
         <p>123 Fifth Avenue, NY 10160, Port-au-Prince, HAITI | Phone: 509-3810-2785. 509-4226-3499. 
 | Email: y_saintil@yahoo.com</p>
        </div>
        <div className="themeFooterBottom">
          <div className="row">
            <div className="col text-start pt-2">
              <span className="ps-4">Copyright &copy; 2021 EUCA Church</span>
            </div>
            <div className="col pt-2 text-end">
              <a href="/privacy-policy">Privacy Policy</a>
              <a className="ps-4 pe-4" href="/contact-us">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;