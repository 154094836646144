import React from "react";
import { Card, Button } from 'react-bootstrap';

interface ICardProps {
  title: string
  cardImage: string,
  cardBody: string,
  buttonMessage?: string,
}

interface ICardState { }

class ThemeCard extends React.Component<ICardProps, ICardState> {
  render() {
    return (
      <Card style={{ width: '100%' }}>
        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
        <Card.Img variant="top" src={this.props.cardImage} />
        <Card.Body>
          <Card.Title><strong>{this.props.title}</strong></Card.Title>
          <Card.Text>
            {this.props.cardBody}
          </Card.Text>
          <Button style={{ width: '55%' }} variant="outline-dark"><strong>{this.props.buttonMessage ? this.props.buttonMessage : "Go"}</strong></Button>
        </Card.Body>
      </Card>
    );
  }
}

export default ThemeCard;