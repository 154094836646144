import React, { Suspense } from 'react';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { createStore} from './store'
import Home from './components/home';
import About from './components/about';
import Causes from './components/causes';

const Loader = () => (
  <div className="App">
    {/* <img src={logo} className="App-logo" alt="logo" /> */}
    <div>loading...</div>
  </div>
);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <Router>
          <Header />
          <Switch>
            <Route path="/about-us">
              <About />
            </Route>
            <Route path="/causes">
              <Causes />
            </Route>
            <Route path="/contact-us">
              {/* <Users /> */}
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </div>
    </Suspense>
  );
}

export default createStore(App);
