import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation, WithTranslation } from 'react-i18next';

import { withStore } from '../../store';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
// @ts-ignore 
// import SimpleReactLightbox from 'simple-react-lightbox';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import { Image } from 'react-bootstrap';

import helpingHandPic from '../../assets/images/about/helpingHand.jpeg'
import causesImages from '../../assets/causes'

const options = {
  buttons: {
    backgroundColor: "rgba(140, 94, 88, 0.8)",
    iconColor: "rgba(241, 191, 152, 0.7)"
  },
  settings: {
    overlayColor: "rgba(255, 237, 225, 1)",
    transitionSpeed: 1000,
    transitionTimingFunction: "linear"
  },
  thumbnails: {
    thumbnailsSize: ["120px", "100px"],
    thumbnailsOpacity: 0.4
  },
  caption: {
    captionColor: "rgba(241, 191, 152, 1)"
  },
  progressBar: {
    size: "4px",
    backgroundColor: "rgba(255, 237, 225, 1)",
    fillColor: "#AF9AB2"
  }
};

interface IAbout extends WithTranslation {
  store: any
}


class About extends React.Component<IAbout> {
  componentDidMount() {
    let language = this.props.store.set('language', 'en');
    this.setState({
      language
    })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState && prevState.language !== prevProps.store.get('language')) {
      let language = this.props.store.get('language');
      this.setState({
        language
      })
    }
  }
  randomImgClass = () => {
    const randomNum = [2, 4];
    const random = Math.floor(Math.random() * randomNum.length);
    console.log(randomNum[random])
    return `col-md-${randomNum[random]} col-6 col-image-with-link`;
  }
  
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="theme-page-header">
          <h5 className="text-white d-flex p-5"><strong>{t('about_us_page.page_header.home')} &gt; <span className="theme-text-orange">{t('about_us_page.page_header.about')}</span></strong></h5>
        </div>
        <div className="container">
          <div className="container theme-container">
            <h4>{t('about_us_page.title')}</h4>
            <div className="row">
              <div className="col-xs-6 col-md-4">
              <Image src={helpingHandPic} alt="" thumbnail></Image>
            </div>
              <div className="col">
                <p className="text-start mt-4">{t('about_us_page.first_section')}</p>
                <p className="text-start mt-4">{t('about_us_page.second_section')}</p>
                <p className="text-start mt-4">{t('about_us_page.third_section')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-custom-container">
          <div className="container theme-container">
            <h4>{t('about_us_page.our_mission.title')}</h4>
            <p className="text-start">{t('about_us_page.our_mission.description')}</p>
          </div>
        </div>
        <div>
          <div className="">
            <div className="container theme-container" style={{overflow: "hidden"}}>
            <h4> {t('about_us_page.gallery')} </h4>
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="content">
                    <div className="row">
                      { causesImages.map((image, index) => 
                          // <div className="col-md-2 col-6 col-image-with-link">
                        <div className={this.randomImgClass()}>
                          <a href={require(`../../assets/${image.src}`).default}>
                            <img
                              key={image.id}
                              height={400}
                              // className={{options.thumbnails}}
                              // className="img-fluid img-thumbnail"
                              // src={require(`../../.${image.src}`).default}
                              src={require(`../../assets/${image.thumbnail}`).default}
                              // src={require('../../assets/images/causes/baptism_group.jpg').default}
                              // src={test}
                              alt="Frozen lakes"
                            />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { About };
export default withTranslation()(withStore(About));