const causesImages = [
  {
    id: 0,
    thumbnail: "images/causes/baptism_group_thumb.jpg",
    src: "images/causes/baptism_group.jpg",
    alt: ""
  },
  {
    id: 1,
    thumbnail: "images/causes/church_main_construction_thumb.jpg",
    src: "images/causes/church_main_construction.jpg",
    alt: ""
  },
  {
    id: 2,
    thumbnail: "images/causes/baptism_group_2_thumb.jpg",
    src: "images/causes/baptism_group_2.jpg",
    alt: ""
  },
  {
    id: 3,
    thumbnail: "images/causes/concert_thumb.jpg",
    src: "images/causes/concert.jpg",
    alt: ""
  },
  {
    id: 4,
    thumbnail: "images/causes/baptism_group_2002_thumb.jpg",
    src: "images/causes/baptism_group_2002.jpg",
    alt: ""
  },
  {
    id: 5,
    thumbnail: "images/causes/grou_ladies_white_thumb.jpg",
    src: "images/causes/grou_ladies_white.jpg",
    alt: ""
  },
  {
    id: 6,
    thumbnail: "images/causes/baptism_lady_thumb.jpg",
    src: "images/causes/baptism_lady.jpg",
    alt: ""
  },
  {
    id: 7,
    thumbnail: "images/causes/kids_choir_group_thumb.jpg",
    src: "images/causes/kids_choir_group.jpg",
    alt: ""
  },
  {
    id: 8,
    thumbnail: "images/causes/choir_singing_red_thumb.jpg",
    src: "images/causes/choir_singing_red.jpg",
    alt: ""
  },
  {
    id: 9,
    thumbnail: "images/causes/marriage_thumb.jpg",
    src: "images/causes/marriage.jpg",
    alt: ""
  },
  {
    id: 10,
    thumbnail: "images/causes/church_ext_thumb.jpg",
    src: "images/causes/church_ext.jpg",
    alt: ""
  },
  {
    id: 11,
    thumbnail: "images/causes/school_6_grade_thumb.jpg",
    src: "images/causes/school_6_grade.jpg",
    alt: ""
  },
  {
    id: 12,
    thumbnail: "images/causes/church_ext_2_thumb.jpg",
    src: "images/causes/church_ext_2.jpg",
    alt: ""
  },
  {
    id: 13,
    thumbnail: "images/causes/school_graduation_2013_thumb.jpg",
    src: "images/causes/school_graduation_2013.jpg",
    alt: ""
  },
  {
    id: 14,
    thumbnail: "images/causes/church_ext_3_thumb.jpg",
    src: "images/causes/church_ext_3.jpg",
    alt: ""
  },
  {
    id: 15,
    thumbnail: "images/causes/school_students_thumb.jpg",
    src: "images/causes/school_students.jpg",
    alt: ""
  },
  {
    id: 16,
    thumbnail: "images/causes/church_group_thumb.jpg",
    src: "images/causes/church_group.jpg",
    alt: ""
  },
  {
    id: 17,
    thumbnail: "images/causes/school_students_2_thumb.jpg",
    src: "images/causes/school_students_2.jpg",
    alt: ""
  }
]

export default causesImages;