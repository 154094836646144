import React from 'react';

const StoreContext = React.createContext(null);


const createStore: any = (WrappedComponent: any) => {
  return class extends React.Component {
    state: any = {
      get: (key: any ) => {
        return this.state[key]
      },
      set: (key: any, value: any) => {
        const state = this.state
        state[key] = value
        this.setState(state)
      },
      remove: (key: any ) => {
        const state = this.state
        delete state[key]
        this.setState(state)
      }
    }
    render() {
      return (
        <StoreContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </StoreContext.Provider>
      )
    }
  }
}
const withStore: any = (WrappedComponent: any) => {
  return class extends React.Component {
    render() {
      return (
        <StoreContext.Consumer>
          {context => <WrappedComponent store={context} {...this.props} />}
        </StoreContext.Consumer>
      )
    }
  }
}
export { createStore, withStore }