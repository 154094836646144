import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Button, NavDropdown } from 'react-bootstrap';
// custom CSS
import '../../assets/css/app.css'
import logo from '../../assets/images/logo.png';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { withStore} from '../../store';


interface IheaderState {};
interface IheaderProps extends WithTranslation  {
  store: any
}

class Header extends React.Component<IheaderProps, IheaderState> {
  constructor(props: IheaderProps) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
    
    this.state = {
      language: 'fr'
    }
  }

  // componentDidMount() {
  //   this.props.store.set('language', 'en');
  // }

  changeLanguage = (eventKey: string | null) => {
    if (eventKey){
      i18n.changeLanguage(eventKey);
      this.props.store.set('language', eventKey)
      this.setState({
        language: eventKey
      })
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="nav-header"></div>
        <div className="container">
          <Navbar collapseOnSelect expand="lg" sticky="top">
            <Navbar.Brand href="/home">
              <img
                src={logo}
                height="70"
                className="d-inline-block align-top"
                alt="Blessing Home Ministry"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Item>
                  <Nav.Link eventKey="1" href="/Home"><strong>{t('home')}</strong></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2" href="/about-us"><strong>{t('about_us')}</strong></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="3" href="/causes"><strong>{t('causes')}</strong></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="4" href="/contact-us"><strong>{t('contact_us')}</strong></Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav className="ms-auto">
                <NavDropdown title={<span><FontAwesomeIcon icon={faLanguage} /></span>} id="language-list">
                  <NavDropdown.Item eventKey="en" onSelect={this.changeLanguage}>English</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="fr" onSelect={this.changeLanguage}>Français</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="hk" onSelect={this.changeLanguage}>Haitien Kreyol</NavDropdown.Item>
                </NavDropdown>
                <Button className="donate-btn" variant="success"><strong>{t('donate')}</strong></Button>{' '}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}
export { Header };
export default withTranslation()(withStore(Header));