import React, { HtmlHTMLAttributes } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation, WithTranslation } from 'react-i18next';

import { withStore } from '../../store';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
// @ts-ignore 
// import SimpleReactLightbox from 'simple-react-lightbox';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
// import test from '../../assets/images/causes/baptism_group.jpg';

import causesImages from '../../assets/causes'

const options = {
  buttons: {
    backgroundColor: "rgba(140, 94, 88, 0.8)",
    iconColor: "rgba(241, 191, 152, 0.7)"
  },
  settings: {
    overlayColor: "rgba(255, 237, 225, 1)",
    transitionSpeed: 1000,
    transitionTimingFunction: "linear"
  },
  thumbnails: {
    thumbnailsSize: ["120px", "100px"],
    thumbnailsOpacity: 0.4
  },
  caption: {
    captionColor: "rgba(241, 191, 152, 1)"
  },
  progressBar: {
    size: "4px",
    backgroundColor: "rgba(255, 237, 225, 1)",
    fillColor: "#AF9AB2"
  }
};

interface IAbout extends WithTranslation {
  store: any
}


class Causes extends React.Component<IAbout> {
  randomImgClass = () => {
    const randomNum = [2, 4];
    const random = Math.floor(Math.random() * randomNum.length);
    console.log(randomNum[random])
    return `col-md-${randomNum[random]} col-6 col-image-with-link`;
  }

  render() {
    return (
      <div>
        <div className="theme-page-header">
          <h5 className="text-white d-flex p-5"><strong>Home &gt; <span className="theme-text-orange">Causes</span></strong></h5>
        </div>
        <div className="container theme-container" style={{overflow: "hidden"}}>
        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            <div id="gallery-with-links" className="content">
              <div className="row">
                { causesImages.map((image, index) => 
                    // <div className="col-md-2 col-6 col-image-with-link">
                  <div className={this.randomImgClass()}>
                    <a href={require(`../../assets/${image.src}`).default}>
                      <img
                        key={image.id}
                        height={400}
                        // className={{options.thumbnails}}
                        // className="img-fluid img-thumbnail"
                        // src={require(`../../.${image.src}`).default}
                        src={require(`../../assets/${image.thumbnail}`).default}
                        // src={require('../../assets/images/causes/baptism_group.jpg').default}
                        // src={test}
                        alt="Frozen lakes"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div >
      </div >
    )
  }
}

export { Causes };
export default withTranslation()(withStore(Causes));