import React from "react";
import { Carousel } from 'react-bootstrap';

interface ICarouselProps {
  props?: any;
  sliders: {
    captionHeader: string;
    captionBody: string;
    img: string,
    alt: string,
  }[]
}

interface ICarouselState { }

class HomeCarousel extends React.Component<ICarouselProps, ICarouselState> {
  state = {
    reload: false,
    sliders: this.props.sliders[0].captionHeader
  }
  
  render() {
    return (
      <Carousel controls={true} nextLabel="" prevLabel="" indicators={true}>

        {this.props.sliders.map((value, index) => {
          return <Carousel.Item key={`carousel-${index}`}>
            <img
              className="d-block w-100 carousel-img img-fluid"
              src={value.img}
              alt={value.alt}
            />
            <Carousel.Caption>
              <h1><strong>{value.captionHeader}</strong></h1>
              <p>{value.captionBody}</p>
            </Carousel.Caption>
          </Carousel.Item>
        })}
      </Carousel>
    );
  }
}
export default HomeCarousel;